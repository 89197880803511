<template>
  <div class="login-page">
    <div class="wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-8 offset-md-2">
            <div class="login__header">
              <div class="login__header--logo">
                <a href="/">
                  <img src="../assets/img/header-logo.png" alt="" />
                </a>
              </div>

              <div class="login__form">
                <form @submit.prevent="sign" v-if="ecpSign == false">
                  <div class="form__block--form">
                    <div class="form__block--subtitle">Вход</div>

                    <div class="login__tab">
                      <div class="row">
                        <div class="col-lg-6">
                          <p
                            class="toggle__btn"
                            @click="ecpSign = true"
                            v-bind:class="{ toggle__active: ecpSign == true }"
                          >
                            ЭЦП
                          </p>
                        </div>
                        <div class="col-lg-6">
                          <p
                            class="toggle__btn"
                            @click="ecpSign = false"
                            v-bind:class="{ toggle__active: ecpSign == false }"
                          >
                            Логин
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form__login--line">
                          <div class="form__login--input">
                            <label>
                              <div class="label__login--icon">
                                <img src="../assets/img/mail_login-icon.svg" />
                              </div>
                              <input
                                type="text"
                                class="input-linck"
                                required=""
                                v-model="email"
                                name="email"
                                value=""
                                placeholder="Электронная почта"
                              />
                            </label>
                            <span class="input-required" v-if="emailError"
                              >Неверное имя пользователя или пароль!</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form__login--line">
                          <div class="form__login--input">
                            <label>
                              <div class="label__login--icon">
                                <img
                                  src="../assets/img/password_login-v-icon.svg"
                                  v-if="inputType"
                                  @click="inputType = false"
                                />
                                <img
                                  src="../assets/img/password_login-icon2.svg"
                                  v-else
                                  @click="inputType = true"
                                />
                              </div>
                              <input
                                type="password"
                                v-if="!inputType"
                                class="input-linck"
                                v-model="password"
                                required=""
                                name="password"
                                value=""
                                placeholder="Пароль"
                              />
                              <input
                                type="text"
                                v-else
                                class="input-linck"
                                v-model="password"
                                required=""
                                name="password"
                                value=""
                                placeholder="Пароль"
                              />
                            </label>
                            <span class="input-required" v-if="passwordError"
                              >Неверное имя пользователя или пароль!</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form__login--input">
                          <button class="input__button" type="submit">Войти</button>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div
                          class="form__login--forgot"
                          @click="$router.push('/recovery/1')"
                        >
                          <a>Забыли свой пароль?</a>
                        </div>
                      </div>
                      <!-- <div class="col-lg-12" @click="$router.push('/registration')">
                        <div class="form__login--registr">
                          <a>Регистрация</a>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </form>

                <form @submit.prevent="handleSend()" v-if="ecpSign == true">
                  <div class="form__block--form">
                    <div class="form__block--subtitle">Вход</div>

                    <div class="login__tab">
                      <div class="row">
                        <div class="col-lg-6">
                          <p
                            class="toggle__btn"
                            @click="ecpSign = true"
                            v-bind:class="{ toggle__active: ecpSign == true }"
                          >
                            ЭЦП
                          </p>
                        </div>
                        <div class="col-lg-6">
                          <p
                            class="toggle__btn"
                            @click="ecpSign = false"
                            v-bind:class="{ toggle__active: ecpSign == false }"
                          >
                            Логин
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form__login--input">
                          <button class="input__button">Выбрать сертификат</button>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div
                          class="form__login--forgot"
                          @click="$router.push('/recovery/1')"
                        >
                          <a>Забыли свой пароль?</a>
                        </div>
                      </div>
                      <div class="col-lg-12" @click="$router.push('/registration')">
                        <div class="form__login--registr">
                          <a>Регистрация</a>
                        </div>
                      </div>

                      <div class="col-lg-12" @click="requestSign = true">
                        <div class="form__login--registr">
                          <a>Заявка на регистрацию</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="requestSign" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Заявка на регистрацию
        </v-card-title>

        <form
          class="request__form pa-10 item__column item__ac"
          @submit.prevent="sendRequest()"
        >
          <div class="mb-4 item__100">
            <p class="">Название организации</p>
            <input
              v-model="form.organization"
              placeholder="Введите организацию"
              required
            />
          </div>

          <div class="mb-4 item__100">
            <p>БИН</p>
            <input
              v-model="form.bin"
              minlength="12"
              maxlength="12"
              placeholder="Введите бин"
              required
            />
          </div>

          <div class="mb-4 item__100">
            <p>Телефон</p>
            <masked-input
              v-model.trim="form.phone"
              id="phone"
              mask="\+\7 (111) 111-11-11"
            />
          </div>

          <div class="mb-4 item__100">
            <p>Email</p>
            <input v-model="form.email" placeholder="Введите email" required />
          </div>

          <button class="form__button pointer" type="submit">Отправить</button>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script src="https://js.pusher.com/7.0.3/pusher.min.js"></script>
<script>
import { mapActions } from "vuex";
import MaskedInput from "vue-masked-input";
export default {
  name: "Auth",
  components: {
    MaskedInput,
  },
  data() {
    return {
      form: {
        organization: "",
        bin: "",
        phone: "",
        email: "",
      },

      requestSign: false,
      emailError: false,
      passwordError: false,
      email: "",
      password: "",
      ecpSign: true,
      ready: false,
      inputType: false,
      message: "",
    };
  },
  methods: {
    hasDigit(val) {
      return /^\d+$/.test(val) ? true : false;
    },
    sendRequest() {
      if (!this.hasDigit(this.form.bin)) {
        this.$toast.open({
          message: "Пишите только цифры",
          type: "error",
          position: "bottom",
          duration: 4000,
          queue: true,
        });
      }

      this.$axios({
        method: "post",
        url: this.$API_URL + this.$API_VERSION + "guest/register/request",
        data: {
          bin: this.form.bin,
          phone: this.form.phone,
          organization: this.form.organization,
          email: this.form.email,
        },
      })
        .then((response) => {
          this.$toast.open({
            message: response.data.message,
            type: "success",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
					this.requestSign = false
        })
        .catch((error) => {
					// console.log(error.response.data.errors?.bin);
					// console.log(error.response.data.errors?.email);
					// console.log(error.response.data.errors?.phone);
          this.$toast.open({
            message: `${error.response.data.errors?.bin || ""}  ${error.response.data.errors?.email ?? ""}  ${error.response.data.errors?.phone || ""}`,
            type: "error",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
					
        });
    },
    ...mapActions(["SIGN_IN_USER"]),
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validateInput() {
      if (!this.email) {
        this.emailError = true;
        return false;
      }
      if (!this.password) {
        this.passwordError = true;
        return false;
      }
      if (this.validateEmail(this.email) == false) {
        this.emailError = true;
        return false;
      }
      return true;
    },
    sign() {
      if (this.validateInput() == false) {
        return false;
      }
      this.$axios({
        method: "post",
        url: this.$API_URL + this.$API_VERSION + "login",
        data: {
          email: this.email,
          password: this.password,
        },
      })
        .then((response) => {
          let data_profile = {
            token: response.data.data.token,
            type: true,
            user: true,
            doc_id: this.$route.query.doc_id,
          };
          localStorage.setItem("access_token_kcmr", response.data.data.token);
          this.SIGN_IN_USER(data_profile);
        })
        .catch((error) => {
          this.message = error.response.data.message;

          this.$toast.open({
            message: this.message,
            type: "error",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
        });
    },
    handleSend() {
      if (this.ready == false) {
        this.message = "NCLayer не включен";
        this.$toast.open({
          message: this.message,
          type: "error",
          position: "bottom",
          duration: 4000,
          queue: true,
        });
      } else {
        const data = {
          module: "kz.gov.pki.knca.commonUtils",
          method: "signXml",
          args: [
            "PKCS12",
            "AUTHENTICATION",
            "<login><sessionid>caacda70-fd36-45ed-8d94-45a88890f83a</sessionid></login>",
            "",
            "",
          ],
        };
        this.connection.send(JSON.stringify(data));
      }
    },
    sendEcp() {
      if (!this.esp__array.responseObject) {
        return false;
      }

      this.$axios({
        method: "post",
        url: this.$API_URL + this.$API_VERSION + "login-cms",
        data: {
          xmlCMS: this.esp__array.responseObject,
        },
      })
        .then((response) => {
          let data_profile = {
            token: response.data.data.token,
            type: true,
            user: true,
            doc_id: this.$route.query.doc_id,
          };
          localStorage.setItem("access_token_kcmr", response.data.data.token);
          this.SIGN_IN_USER(data_profile);
        })
        .catch((error) => {
          this.message = error.response.data.message;
          this.$toast.open({
            message: this.message,
            type: "error",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
          console.warn(error);
        });
    },
    getProfile() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "user",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.user = response.data.data;

          localStorage.setItem("role", this.user.role.name);
          this.$router.push("/");
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },
  created() {
    this.connection = new WebSocket("wss://127.0.0.1:13579/");
    this.connection.onopen = () => {
      this.ready = true;
    };
    this.connection.onmessage = (e) => {
      const arr = JSON.parse(e.data);
      const parse__arr = arr;
      this.esp__array = parse__arr;

      if (typeof this.esp__array !== "undefined") {
        this.sendEcp();
      }
    };
  },
  mounted() {

    Pusher.logToConsole = true;
    let pusher = new Pusher("c29c53c1ef3a28531f9a", {
      cluster: "eu",
      encrypted: true,
    });
    let channel = pusher.subscribe("user-channel.1");
    channel.bind('user-event', function (data) {
      console.log(JSON.stringify(data))
    });

    
    // t.getCount();
    // t.getNotifications();
  },
  watch: {},
};
</script>
